



























































































/* eslint-disable */
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

import Datepicker from 'vuejs-datepicker'
import { fr, de } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import { date } from '@/composables/date'

export default defineComponent({
  name: 'datepicker-record',
  components: {
    Datepicker,
  },
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { user, chrome } = useState(['user', 'chrome'])
    const { i18n, i18nSuivi } = useState('my', ['i18n', 'i18nSuivi'])
    const { refreshDossier } = useActions('user', ['refreshDossier'])    
    const { currentLang } = useGetters(['currentLang'])

    const french = ref()
    const deutch = ref()

    var dateSelected = null
    const showLoader = ref(false)
    const isValidateDate = ref(false)
    const errorReload = ref(false)
    const disableBtn = ref(false)
    const planningNoDate = ref(false)

    french.value = fr
    deutch.value = de

    const listeDate = ref<any | null>()
    const formatDate = date => {
      // CONDITION POUR LA LANGUE
      return moment(date).locale(currentLang.value).format('DD/MM/YYYY')
    }

    // TODO plus tard condition pour la langue FR ou DE !
    var payload = {
      Liste: [
        {
          Langue: 'FR',
          NumDossier: _props.parentData.numDossier[0],
          TypeAction: 'DATE',
        },
      ],
    }
    axios
      .post(`${getApiUrl()}/works/planning`, payload)
      .then(response => {
        if (response.status === 200) {
          listeDate.value = response.data.liste[0]
          showLoader.value = true
        }
      })
      .catch(error => {
        // showLoader.value = false
        if (
          error.response.data.message ===
          "Activité impossible dans l'état du dossier"
        ) {
          planningNoDate.value = true
        }
        errorReload.value = true
        isValidateDate.value = true
        //console.log(error)
      })

    // variable a passer au datepicker qui permet de faire une fonction custom pour ajouter les dates
    var enableDate = {
      customPredictor: function (date) {
        // je recupere les dates qui ne doivent pas etre activer
        try {
          var dateNotDisable = listeDate.value
          // Condition pour voir si le tableau de date n'est pas vide et si il correspond bien au bon numero de dossier
          // condition sur la longueur de la liste pour afin d etre sur qu'il n y a pas  elements ( different de la condition null car tableau peut etre vide)
          if (dateNotDisable.liste.length > 0) {
            // je ne desactive pas les dates qui sont dans mon tableau de date
            //console.log(dateNotDisable.liste.length)
            for (let i = 0; i < dateNotDisable.liste.length; i++) {
              if (formatDate(date) === dateNotDisable.liste[i].date) {
                return false
              }
            }
          }
          return true
        } catch (error) {
          return true
        }
      },
    }

    const redirect = () => {
      if (ctx.root.$route.name === 'MyRecordSingle') {
        const t = new Date().getTime()
        const path = `/suivi-dossier/${_props.parentData.numDossier[0]}?t=${t}`
        if (ctx.root.$route.path !== path) {
          ctx.root.$router.push(path)
        }
      } else if (ctx.root.$route.name === 'MyDocumentTest') {
        {
          const t = new Date().getTime()
          const path = `/module-test/${_props.parentData.numDossier[0]}?t=${t}`
          if (ctx.root.$route.path !== path) {
            ctx.root.$router.push(path)
          }
        }
      }
    }
    const onClick = async date => {
      disableBtn.value = true
      var payload = {
        Liste: [
          {
            NumDossier: _props.parentData.numDossier[0],
            TypeAction: 'PLAN',
            DatePlanif: date,
          },
        ],
      }
      await axios
        .post(`${getApiUrl()}/works/planning`, payload)
        .then(response => {
          if (response.status === 200) {
            isValidateDate.value = true
            scrollTo(0, 0)
            refreshDossier()
            redirect()
          }
        })
        .catch(e => {
          logger.error('[FETCH PLANNING] ERROR WS 39')
          isValidateDate.value = false
        })
    }
    const processHtml = (text: string, date) => {
      let html = text.replace('%date', date)
      return html
    }

    const processEanCptDate = (
      text: string,
      ean: string,
      cpt: string,
      date
    ) => {
      let html
      if (ean === null) {
        html = text.replace('(EAN %ean)', ' ')
      } else {
        html = text.replace('%ean', ean)
      }
      html = html.replace('%numCompteur', cpt)
      html = html.replace('%date', date)
      return html
    }
    const processEan = (text: string, ean: string, cpt: string) => {
      let html
      if (ean === null) {
        html = text.replace('(EAN %ean)', ' ')
      } else {
        html = text.replace('%ean', ean)
      }
      html = html.replace('%numCompteur', cpt)
      return html
    }
    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      dateSelected,
      onClick,
      formatDate,
      processHtml,
      processEanCptDate,
      processEan,
      enableDate,
      french,
      currentLang,
      deutch,
      listeDate,
      showLoader,
      i18nSuivi,
      isValidateDate,
      errorReload,
      planningNoDate,
      disableBtn,
    }
  },
})
